import { React, useState, useRef } from 'react';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, orderBy, query, limit, serverTimestamp, doc, addDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDE3w9rN-dz1dXqY5ohqthNojr5jAbPY54",
  authDomain: "superchat-6df3e.firebaseapp.com",
  projectId: "superchat-6df3e",
  storageBucket: "superchat-6df3e.appspot.com",
  messagingSenderId: "567005395259",
  appId: "1:567005395259:web:d1bc3b6778564130dd5f1e",
  measurementId: "G-K20FHKZL1Q"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

console.log(firestore);  // Check the Firestore instance



function App() {
  const [user] = useAuthState(auth);
  console.log('App');

  return (
    <div className="App">
      <header>
        <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/superchat-6df3e.appspot.com/o/Skoog%20Chatten.webp?alt=media&token=9c27816e-df61-41db-a22b-72f9dff4609b" />
        {user ? <SignOut /> : <SignIn />}
      </header>

      <section>
        {user ? <ChatRoom /> : <></>}
      </section>

    </div>
  );
}

function SignIn(){
  console.log('SignIn');
const SignInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
}

  return (
      <button className="SignX" onClick={SignInWithGoogle}>Sign in with Google</button>
  )
}


function SignOut(){
  console.log('SignOut');
  return auth.currentUser && (
    <button className="SignX" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom(){
  const dummy = useRef();

  const messagesRef = collection(firestore, "messages");
  const q = query(messagesRef, orderBy("createdAt"), limit(25));
  const [messages] = useCollectionData(q, {idField: 'id'});
  const [formValue, setFormValue] = useState('')

  const sendMessage = async(e) => {
    e.preventDefault();
    const { uid, photoURL } = auth.currentUser;
    await addDoc(messagesRef, {
      text: formValue,
      createdAt: serverTimestamp(),
      uid,
      photoURL
    });
    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <header>
        <img className="logo" src="https://firebasestorage.googleapis.com/v0/b/superchat-6df3e.appspot.com/o/Skoog%20Chatten.webp?alt=media&token=9c27816e-df61-41db-a22b-72f9dff4609b" />
        <SignOut />
      </header>
      <main>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <div ref={dummy}></div>
      </main>
      <form onSubmit={sendMessage}>
        <input value={formValue} onChange={(e) => setFormValue(e.target.value)}/>
        <button type="submit">
          Submit
        </button>
      </form>
    </>
  )
}

function ChatMessage(props){
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'recived';

  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL} />
      <p>{text}</p>
    </div>
  )
}
export default App;
